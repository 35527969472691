<template>
  <div class="mx-lg-2 mx-xs-0 locale-changer LocaleChanger" :class="{'mt-2': !header, 'mx-2': !header}" v-if="languages.length > 1">
    <select class="form-select" v-model="$i18n.locale" @change="langChanged($i18n.locale)">
      <option v-for="(lang, i) in languages" :key="`Lang${i}`" :value="lang">
        {{ lang.toUpperCase() }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'LocaleChanger',
  data () {
    return { languages: ['sk', 'en', 'de', 'es', 'hu', 'ru', 'fr'] }
  },
  mounted() {
    const savedLang = localStorage.getItem('selectedLanguage');

    if (savedLang) {
      this.$i18n.locale = savedLang;
    }
  },
  methods: {
    langChanged (selectedLang) {
      localStorage.setItem('selectedLanguage', selectedLang);
      window.location.reload()
    }
  },
  props: {
    header: Boolean
  }
}
</script>