import { createI18n } from 'vue-i18n'


import sk from "./../locales/sk.json";
import en from "./../locales/en.json";
import de from "./../locales/de.json";
import es from "./../locales/es.json";
import hu from "../locales/hu.json";
import ru from "./../locales/ru.json";
import fr from "./../locales/fr.json";

//TODO: Don't forget to add in LocaleChanger.vue
const translations = {
    sk: sk,
    en: en,
    de: de,
    es: es,
    hu: hu,
    ru: ru,
    fr: fr,
}

let lang = 'sk';
if (typeof window !== 'undefined') {
    const savedLang = localStorage.getItem('selectedLanguage');
    lang = savedLang || 'sk';
}

export const i18n = createI18n({
    legacy: false,
    locale: lang,
    fallbackLocale: 'sk',
    messages: translations,
});