// import axios from 'axios';
import router from '../../router'
import {i18n} from "@/store/i18n";

const API_URL = process.env.VUE_APP_API_URL + 'api'
const API_URL_OFFER = process.env.VUE_APP_API_URL + 'api/offer'
const API_URL_LANDREGISTER = process.env.VUE_APP_API_URL + 'api/landregister'
const API_URL_COMMAND = process.env.VUE_APP_API_URL + 'api/offer/command'

const GOOGLE_MAP_API_KEY = 'AIzaSyB03xpaAoXQi4HbC-cpLgUXFdPO3Jipz-k'
const API_URL_GOOGLE_MAP_GEOCODING_BASE = 'https://maps.googleapis.com/maps/api/geocode/json?address='

export default {
    async submitSearchCommand(context, payload) {
        const response = await fetch( API_URL_COMMAND, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: payload.data
            })
        });
        return await response.json();
    },
    async getNewOfferInitDataCountry(context) {
        const response = await fetch( API_URL_LANDREGISTER + '/countries' , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else
        if (!response.ok) {
            return new Error(responseData.message || i18n.global.t('actions.register_failed'));
        }

        return responseData;
    },
    async getNewOfferLandRegisterData(context, payload) {

        const url = payload.requestContext;
        let bodyContent = {};

        if (payload.requestContext === 'regions') {
            bodyContent = { country: payload.requestContent.country }
        } else if (payload.requestContext === 'districts') {
            bodyContent = {
                country: payload.requestContent.country,
                region: payload.requestContent.region
            }
        } else if (payload.requestContext === 'areas') {
            bodyContent = {
                country: payload.requestContent.country,
                region: payload.requestContent.region,
                district: payload.requestContent.district
            }
        } else if (payload.requestContext === 'areanumber') {
            bodyContent = {
                country: payload.requestContent.country,
                region: payload.requestContent.region,
                district: payload.requestContent.district,
                area: payload.requestContent.area
            }
        }

        const response = await fetch( API_URL_LANDREGISTER + '/' + url , {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify(
                bodyContent
            )
        });

        const responseData = await response.json();
        if(response.status === 401) {
            await forcedLogout()
            return
        } else
        if (!response.ok) {
            return new Error(responseData.message || i18n.global.t('actions.register_failed'));
        }

        return responseData;
    },
    // async submitNewOffer(context, payload) {
    //     let body = {
    //         type: payload.requestContent.firstStep.offerSelling,
    //         property: payload.requestContent.firstStep.offerType.picked,
    //         property_type: payload.requestContent.firstStep.offerType.other.selected,
    //         area_number: payload.requestContent.secondStep.requestedAreaNumber,
    //         property_list_number: payload.requestContent.secondStep.offerLV,
    //         address: payload.requestContent.secondStep.offerAddress,
    //         title_image: payload.requestContent.thirdStep.offerTitleImage,
    //         image_gallery: payload.requestContent.thirdStep.offerPhotogalery,
    //         data: payload.requestContent.thirdStep,
    //         parcel_type: payload.requestContent.secondStep.offerParcelType.selected,
    //         parcel_number: payload.requestContent.secondStep.offerPC,
    //         repeat: payload.repeatPayment,
    //         flat_number: payload.requestContent.secondStep.offerFlatNumber.value
    //     }
    //
    //     const formData = new FormData();
    //     formData.append("type", body.type);
    //     formData.append("property", body.property);
    //     formData.append("property_type", body.property_type);
    //     formData.append("property_list_number", body.property_list_number);
    //     formData.append("area_number", body.area_number);
    //     formData.append("address", body.address);
    //     formData.append("title_image", body.title_image);
    //     formData.append("data", JSON.stringify(body.data));
    //     formData.append("payment_method", body.payment_method);
    //     formData.append("parcel_type", body.parcel_type);
    //     formData.append("parcel_number", body.parcel_number);
    //     formData.append("repeat", body.repeat);
    //     formData.append("flat_number", body.flat_number);
    //
    //     Array.prototype.forEach.call(body.image_gallery, (file) => {
    //         formData.append('file[]', file)
    //     })
    //
    //     const response = await fetch( API_URL_OFFER , {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Authorization': 'Bearer ' + context.rootState.auth.token
    //         },
    //         body: formData
    //     });
    //
    //     const responseData = await response.json();
    //
    //     if(response.status === 401) {
    //         await forcedLogout()
    //         return
    //     }
    //     // else if (!response.ok) {
    //     //     return new Error(responseData.message || 'Registrácia ponuky zlyhala.');
    //     // }
    //
    //     return responseData
    // },
    async submitNewOfferFirstStep(context, payload) {
        // console.log(payload)
        const formData = new FormData();
        formData.append("type", payload.requestContent.firstStep.offerSelling);
        formData.append("property", payload.requestContent.firstStep.offerType.picked);
        formData.append("propertyName", i18n.global.t('new_offer.' + payload.requestContent.firstStep.offerType.picked));
        formData.append("roomsTranslation", i18n.global.t('new_offer.rooms_number'));
        formData.append("property_type", payload.requestContent.firstStep.offerType.other.selected);
        formData.append("property_list_number", payload.requestContent.secondStep.offerLV);
        formData.append("area_number", payload.requestContent.secondStep.requestedAreaNumber);
        formData.append("address", payload.requestContent.secondStep.offerAddress);
        formData.append("title_image", payload.requestContent.thirdStep.offerTitleImage);
        formData.append("data", JSON.stringify(payload.requestContent.thirdStep));
        formData.append("parcel_type", payload.requestContent.secondStep.offerParcelType.selected);
        formData.append("parcel_number", payload.requestContent.secondStep.offerPC);
        formData.append("repeat", payload.repeatPayment);
        formData.append("flat_number", payload.requestContent.secondStep.offerFlatNumber.value);

        const response = await fetch( API_URL_OFFER, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: formData
        });
        const responseData = await response.json();
        // console.log(responseData)

        if(response.status === 401) {
            await forcedLogout()
            return false
        }
        // else if (!response.ok) {
        //     return new Error(responseData.message || 'Registrácia ponuky zlyhala.');
        // }

        return responseData
    },
    async submitNewOfferSecondStep(context, payload) {

        const formData = new FormData();
        formData.append("offer_id", payload.offerId);
        formData.append('file[]', payload.image)

        // Array.prototype.forEach.call(payload.image_gallery, (file) => {
        //     formData.append('file[]', file)
        // })

        console.log('Sending second step request')
        const response = await fetch( API_URL_OFFER + '/upload-gallery', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: formData
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return false
        }
        // else if (!response.ok) {
        //     return new Error(responseData.message || 'Registrácia ponuky zlyhala.');
        // }

        return responseData
    },
    async checkOfferDuplicate(context, payload) {
        // console.log(payload)
        const response = await fetch(API_URL + '/offer/check-duplicate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify({
                property: payload.property,
                property_list_number: payload.property_list_number,
                flat_number: payload.flat_number,
                area_number: payload.area_number,
                parcel_number: payload.parcel_number,
            })
        });


        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        }

        return responseData;
    },
    async getOffers(context) {

        let userId;
        if (context.rootState.auth.loggedIn === false) {
            userId = 0;
        } else {
            userId = context.rootState.auth.user.id
        }
        const response = await fetch(API_URL_OFFER + '/get-all-offers', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify({
                user_id: userId,
            })
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_fav_failed'));
        }

        return responseData;
    },
    async getOffersPage(context, payload) {

        let userId;
        if (context.rootState.auth.loggedIn === false) {
            userId = 0;
        } else {
            userId = context.rootState.auth.user.id
        }

        // console.log(payload)
        const response = await fetch(API_URL_OFFER + '/filter/page', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify({
                user_id: userId,
                sort: payload.sort,
                city: payload.city,
                title: payload.title,
                typeOfHouse: payload.typeOfHouse,
                types: payload.types,
                properties: payload.properties,
                min_price: payload.min_price,
                max_price: payload.max_price,
                page: payload.page
            })
            // city: string (mesto podla ktoreho si vyberiem z db okres, kraj)
            // types: array|max:2 (typ Predaj alebo Prenájom alebo obe)
            // properties: array|max:4 (Dom, Byt, Pozemok, Ostatné)
            // min_price: int (minimálna cena)
            // max_price: int (max cena)
            // page: default = 1
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        }

        return responseData;
    },
    // async getMapPins(context, payload) {
    //     const response = await fetch(API_URL_OFFER + '/pins/range', {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + context.rootState.auth.token
    //         },
    //         body: JSON.stringify({
    //             from_latitude: payload.fromLat,
    //             from_longitude: payload.fromLng,
    //             to_latitude: payload.toLat,
    //             to_longitude: payload.toLng,
    //         })
    //     });
    //
    //     const responseData = await response.json();
    //
    //     if(response.status === 401) {
    //         await forcedLogout()
    //         return
    //     } else if (!response.ok) {
    //         throw new Error(responseData.message || 'Nepodarilo sa zaobstarať obľúbené ponuky');
    //     }
    //
    //     return responseData;
    // },
    async getMapPins(context) {
        const response = await fetch(API_URL_OFFER + '/pins', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_fav_failed'));
        }

        return responseData;
    },
    async getMapPinOffers(context, payload) {
        const response = await fetch(API_URL_OFFER + '/pin/' + payload.lat + '/' + payload.lng, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_fav_failed'));
        }

        return responseData;
    },
    async getOfferDetails(context, payload) {
        const response = await fetch(API_URL_OFFER + '/' + payload.offerId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_offer_failed'));
        }

        return responseData;
    },
    async submitFavoriteOffer(context, payload) {
        const response = await fetch( API_URL + '/favoriteoffers' , {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify({
                offer_id: payload.offerId,
            })
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            return new Error(responseData.message || i18n.global.t('actions.register_failed'));
        }

        return responseData;
    },
    async getFavoriteOffers(context) {
        const response = await fetch(API_URL + '/favoriteoffers', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_fav_failed'));
        }

        return responseData;
    },
    async deleteFavoriteOffer(context, payload) {
        const response = await fetch(API_URL + '/favoriteoffers/delete/' + payload.favoriteOfferId, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify({
                favorite_offer_id: payload.favoriteOfferId,
            })
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.delete_failed'));
        }

        return responseData;
    },
    async getGeocodingCoordinates(context, payload) {

        let geocodingFullApiUrl =
            API_URL_GOOGLE_MAP_GEOCODING_BASE +
            payload.address + ',+' +
            payload.city + ',+' +

            payload.country + ',+' +
            '&key=' +
            GOOGLE_MAP_API_KEY;

        const response = await fetch(geocodingFullApiUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        const responseData = await response.json();
        if(response.status === 401) {
            await forcedLogout()
            return
        } else
        if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.data_failed'));
        }

        return responseData;
    },
    async getMyOffers(context, payload) {

        const response = await fetch(payload.url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify({
                sortBy: payload.sorting.sortingBy,
                direction: payload.sorting.direction,
            })
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        }else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_fav_failed'));
        }

        return responseData;
    },
    async getAdminOffers(context, payload) {

        const response = await fetch(payload.url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify({
                sortBy: payload.sorting.sortingBy,
                direction: payload.sorting.direction,
            })
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_fav_failed'));
        }

        return responseData;
    },
    async toggleAdminOffer(context, payload) {

        const response = await fetch(API_URL + '/useroffers/toggle/' + payload.id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
        })

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_fav_failed'));
        }

        return responseData;

        // fetch(API_URL + '/useroffers/toggle/' + payload.id, {
        //     method: 'GET',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + context.rootState.auth.token
        //     },
        // }).then(response => {
        //     return error(response.json())
        // });

    },
    async getOfferPaymentData(context, payload) {

        // console.log(payload)

        const response = await fetch(API_URL + '/useroffers/pay', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify({
                offer_id: payload.offerId,
                repeat: payload.repeatPayment
            })
        });

        const responseData = await response.json();

        if (response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_fav_failed'));
        }

        return responseData;
    },
    async disablePaymentRepeat(context, offerId) {
        // console.log(offerId)
        const response = await fetch(API_URL + '/useroffers/stop-payment', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: JSON.stringify({
                offer_id: offerId,
            })
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.fetch_payment_failed'));
        }

        return responseData;
    },
    async deleteMyOffer(context, payload) {
        // console.log(payload.id)
        const response = await fetch(API_URL + '/useroffers/' + payload.id, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.delete_failed_2'));
        }

        return responseData;
    },
    async deleteImage(context, payload) {
        const response = await fetch(API_URL + '/image/' + payload.id, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            throw new Error(responseData.message || i18n.global.t('actions.delete_failed_3'));
        }

        return responseData;
    },
    // async test(context) {
    //     const response = await fetch(API_URL + '/test/401', {
    //         method: 'GET',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Authorization': 'Bearer ' + context.rootState.auth.token
    //         },
    //     });
    //
    //     const responseData = await response.json();
    //     console.log(response)
    //
    //     if(response.status === 401) {
    //        await forcedLogout()
    //     } else if (!response.ok) {
    //         throw new Error(responseData.message || 'Nepodarilo sa vymazať obrazok');
    //     }
    //
    //     return responseData;
    // },
    async editOffer(context, payload) {
        console.log(payload)
        const formData = new FormData();
        formData.append("type", payload.requestContent.firstStep.offerSelling);
        formData.append("property", payload.requestContent.firstStep.offerType.picked);
        formData.append("property_type", payload.requestContent.firstStep.offerType.other.selected);
        formData.append("property_list_number", payload.requestContent.secondStep.offerLV);
        formData.append("area_number", payload.requestContent.secondStep.requestedAreaNumber);
        formData.append("address", payload.requestContent.secondStep.offerAddress);
        formData.append("data", JSON.stringify(payload.requestContent.thirdStep));
        formData.append("parcel_type", payload.requestContent.secondStep.offerParcelType.selected);
        formData.append("parcel_number", payload.requestContent.secondStep.offerPC);
        formData.append("flat_number", payload.requestContent.secondStep.offerFlatNumber.value);
        formData.append("propertyName", i18n.global.t('new_offer.' + payload.requestContent.firstStep.offerType.picked));
        formData.append("roomsTranslation", i18n.global.t('new_offer.rooms_number'));

        if (payload.requestContent.thirdStep.offerTitleImage !== null) {
            formData.append("title_image", payload.requestContent.thirdStep.offerTitleImage);
        } else {
            formData.append("title_image", null);
        }

        Array.prototype.forEach.call(payload.requestContent.thirdStep.offerPhotogalery, (file) => {
            formData.append('file[]', file)
        })

        const response = await fetch( API_URL + '/useroffers/edit/' + payload.id , {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.rootState.auth.token
            },
            body: formData
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            return new Error(responseData.message || i18n.global.t('actions.register_failed'));
        }

        return responseData;
    },
    setSelectedOffer(context, id) {
        context.rootState.offer.selectedOffer.id = id
    }
}

async function forcedLogout() {
    localStorage.removeItem('user');
    localStorage.removeItem('auth');
    await router.replace({ path: '/prihlasenie' })
    router.go()
}

// function error(response) {
//     if(response.status === 401) {
//         this.$store.detach('auth/forcedLogout');
//         throw new Error(response.message || 'Prihlásenie už nieje platné');
//     } else if (!response.status === 200) {
//         throw new Error(response.message || 'Nepodarilo sa zaobstarať obľúbené ponuky');
//     }
//     return response
// }

// Route::get('/landregister/countries', [LandRegisterController::class, 'getCountries'])->name('landregister.getcountries'); // Krajiny
// Route::post('/landregister/regions', [LandRegisterController::class, 'getRegions'])->name('landregister.getregions'); // Kraje
// Route::post('/landregister/districts', [LandRegisterController::class, 'getDistricts'])->name('landregister.getdistricts'); // Okresy
// Route::post('/landregister/areas', [LandRegisterController::class, 'getAreas'])->name('landregister.getareas'); // Mesta - Oblasti
// Route::post('/landregister/areanumber', [LandRegisterController::class, 'getAreaNumber'])->name('landregister.getareanumber'); // Cisla oblasti