// import axios from 'axios';
import router from '../../router'
import {i18n} from '@/store/i18n'

let auth = JSON.parse(localStorage.getItem('auth'));

let API_URL = process.env.VUE_APP_API_URL + 'api'
let API_URL_MY_PROFILE = API_URL + '/myprofile'

export default {
    async login(context, payload) {
        const response = await fetch(API_URL + '/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: payload.email,
                password: payload.password,
            })
        });

        const responseData = await response.json();

        if (!response.ok) {
            context.commit('loginFailure');
            throw new Error(responseData.message || i18n.global.t('actions.login_failed'));
        }

        if (responseData.user.role_id === 1) {
            context.commit('setAdmin');
        }

        localStorage.setItem('auth', JSON.stringify(responseData));
        context.commit('loginSuccess', responseData);
    },
    // async loginAdmin(context, payload) {
    //     const response = await fetch(API_URL + '/login', {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             email: payload.email,
    //             password: payload.password,
    //         })
    //     });
    //
    //     const responseData = await response.json();
    //
    //     if (!response.ok) {
    //         context.commit('loginFailure');
    //         throw new Error(responseData.message || 'Prihlásenie zlyhalo.');
    //     }
    //
    //     localStorage.setItem('adminAuth', JSON.stringify(responseData));
    //     console.log(responseData);
    //     context.commit('loginSuccess', responseData);
    // },
    autoLogin(context) {
        if (localStorage.getItem("auth") === null) {
            localStorage.removeItem('user');
            localStorage.removeItem('auth');
            context.commit('logout');
        } else if (auth.token !== null){
            if (auth.user.role_id === 5) {
                context.commit('registrationUnfinished');
            }
            if (auth.user.role_id === 1) {
                context.commit('setAdmin');
            }
            context.commit('loginSuccess', JSON.parse(localStorage.getItem('auth')));
        }
    },
    checkCompliance(context) {
        let compliance;
        if (localStorage.getItem("compliance") === null) {
            context.commit('setCompliance', false);
        } else {
            compliance = JSON.parse(localStorage.getItem('compliance'));
            if (compliance) {
                context.commit('setCompliance', true);
            }
        }
    },
    acceptCompliance(context) {
        localStorage.setItem('compliance', true);
        context.commit('setCompliance', true);
    },
    checkUserDataIntegrity(context) {
        auth = JSON.parse(localStorage.getItem('auth'));
        if (auth !== null) {
            if (auth.user.role_id === 5) {
                context.commit('registrationUnfinished');
            }
        }
    },
    async register(context, payload) {
        const response = await fetch( API_URL + '/register', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                role: payload.role,
                email: payload.email,
                password: payload.password,
                password_confirmation: payload.password_confirmed,
                fullname: payload.fullname,
                address: payload.address,
                city: payload.city,
                postcode: payload.postcode,
                phone: payload.phone
            })
        });
        const responseData = await response.json();
        // console.log(response);
        if (response.status === 200) {
            responseData.status = 200
            localStorage.setItem('auth', JSON.stringify(responseData));
            context.commit('registerSuccess', responseData);
        } else if (response.status === 422) {
            responseData.status = 422
        } else {
            responseData.status = 500
            context.commit('registerFailure');
        }

        return responseData
    },
    async logout(context) {
        // console.log(context.state.token);
        const response = await fetch( API_URL + '/logout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.state.token
            },
        });

        const responseData = await response.json();
        if(response.status === 401) {
            await forcedLogout()
            return
        } else if (!response.ok) {
            return new Error(responseData.message || i18n.global.t('actions.logout_failed'));
        }
        localStorage.removeItem('user');
        localStorage.removeItem('auth');
        context.commit('logout');
        await router.push({ path: '/prihlasenie' })
        router.go()
    },
    async getUserData(context) {
        // console.log(context.state.token);
        const response = await fetch(API_URL_MY_PROFILE, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.state.token
            },
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        }

        if (!response.ok) {
            console.log(responseData);
            throw new Error(responseData.message || i18n.global.t('actions.fetch_data_failed'));
        }

        // console.log(responseData);
        context.commit('setUser', responseData);
        localStorage.setItem('user', JSON.stringify(responseData));

        return responseData;
    },
    async getUserDataExternalLogin(context, payload) {
        const response = await fetch(API_URL + '/getuserdata', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + payload.token
            },
        });

        const responseData = await response.json();

        if (!response.ok) {
            console.log(responseData);
            throw new Error(responseData.message || i18n.global.t('actions.fetch_data_failed'));
        }

        // console.log(responseData);
        let auth = {token: payload.token, user: responseData}
        context.commit('loginSuccess', auth);
        localStorage.setItem('auth', JSON.stringify(auth));

        return responseData;
    },
    async changeUserData(context, payload) {

        const response = await fetch( API_URL_MY_PROFILE , {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.state.token
            },

            body: JSON.stringify({
                role: payload.userRole,
                email: payload.userEmail,
                fullname: payload.userName,
                address: payload.userAddress,
                city: payload.userCity,
                postcode: payload.userPSC,
                phone: payload.userTel
            })
        });

        const responseData = await response.json();

        if(response.status === 401) {
            await forcedLogout()
            return
        } else {
            const auth = JSON.parse(localStorage.getItem('auth'));
            localStorage.setItem('user', JSON.stringify(responseData.user));
            localStorage.setItem('auth', JSON.stringify({token: auth.token,user: responseData.user}));
            context.commit('setUser', responseData);
        }

        // if (!response.ok) {
        //     return new Error(responseData.message || 'Zmena údajov zlyhala.');
        // }
        return responseData;
    },
    async changeUserPassword(context, payload) {
        const response = await fetch( API_URL_MY_PROFILE + '/changepassword' , {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.state.token
            },
            body: JSON.stringify({
                old_password: payload.oldPassword,
                password: payload.newPassword,
                password_confirmation: payload.confirmPassword,
            })
        });

        const responseData = await response.json();

        // console.log(responseData);

        if(response.status === 401) {
            await forcedLogout()
            return
        }

        if (!response.ok) {
            return new Error(responseData.message || i18n.global.t('actions.pw_failed'));
        }

    },
    async resetUserPassword(context, payload) {
        // console.log(payload)
        const response = await fetch( API_URL + '/forgot-password' , {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: payload.email,
            })
        });

        const responseData = await response.json();

        // console.log(responseData);

        if(response.status === 401) {
           await forcedLogout()
           return
        }

        if (!response.ok) {
            return new Error(responseData.message || i18n.global.t('actions.pw_failed'));
        }

    },
    async getNewPassword(context, payload) {
        // console.log(payload)
        const response = await fetch( API_URL + '/reset-password' , {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: payload.passwordFormData.newPassword,
                password_confirmation: payload.passwordFormData.newPassword,
                email: payload.email,
                token: payload.token
            })
        });

        const responseData = await response.json();

        // console.log(responseData);

        if(response.status === 401) {
           await forcedLogout()
        } else if (!response.ok) {
            return new Error(responseData.message || i18n.global.t('actions.pw_failed'));
        }
    },
    async deleteAccount(context) {
        const response = await fetch(API_URL_MY_PROFILE + '/delete', {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + context.state.token
            },
        });

        const responseData = await response.json();
        console.log(responseData)

        if (response.status === 401) {
            await forcedLogout()
            return
        }

        localStorage.removeItem('auth');
        localStorage.removeItem('user');
        context.commit('logout');
    },
}

async function forcedLogout() {
    localStorage.removeItem('user');
    localStorage.removeItem('auth');
    await router.replace({path: '/prihlasenie'})
    router.go()
}

// https://arcane-beyond-55145.herokuapp.com/api/myprofile - moj ucet - GET
// https://arcane-beyond-55145.herokuapp.com/api/myprofile - PUT to je na update profilu
// https://arcane-beyond-55145.herokuapp.com/api/myprofile/changepassword - POST
// https://arcane-beyond-55145.herokuapp.com/api/myprofile/changerole - PUT update roly
// https://arcane-beyond-55145.herokuapp.com/api/myprofile/delete - DELETE profile
// na response = 401 => logout
